import {useEffect, useState} from "react";

/**
 * @param divider
 * @returns {undefined}
 */
export default function useAutoHeightImage({
                                             xs = 1,
                                             sm = 2,
                                             md = 3,
                                             lg = 4,
                                             xl = 8,
                                             xxl = 8,
                                             min = 300,
                                             equalDims = true,
                                             containerRef = null
                                           }) {
  const [autoHeight, setAutoHeight] = useState();

  const setHeightCb = (containerRef = null) => {
    let height;

    const containerWidth = (containerRef && containerRef.current) ? containerRef.current.offsetWidth : window.innerWidth;

    if (containerWidth > 2000) {
      height = containerWidth / xxl;
    } else if (containerWidth > 1280) {
      height = containerWidth / xl;
    } else if (containerWidth > 1024) {
      height = containerWidth / lg;
    } else if (containerWidth > 768) {
      height = containerWidth / md;
    } else if (containerWidth > 640) {
      height = containerWidth / sm;
    } else {
      height = containerWidth / xs;
    }

    if (equalDims) {
      setAutoHeight({height: height < min ? min: height, width: height < min ? min: height});
    } else {
      setAutoHeight({height: height < min ? min: height});
    }
  }

  useEffect(() => {
    console.log(containerRef);
    setHeightCb(containerRef);

    if (containerRef && containerRef.current) {
      console.log(containerRef?.current)
      containerRef.current.addEventListener("resize", () => setHeightCb(containerRef));
    } else {
      window.addEventListener("resize", setHeightCb);
    }

    return function cleanup() {
      if (containerRef && containerRef.current) {
        containerRef.current.addEventListener("resize", setHeightCb);
      } else {
        window.removeEventListener('resize', setHeightCb)
      }
    }
  }, [containerRef?.current]);

  return autoHeight;
}

