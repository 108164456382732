import {PRODUCT_FIELDS} from "./ProductFields";
import {PRODUCT_IMAGE_FIELDS} from "./ProductImageFields";
import {PRODUCT_VARIANT_FIELDS} from "./ProductVariantFields";

export const COLLECTION_FIELDS = `
    #graphql
    fragment CollectionFields on Collection {
        title
        descriptionHtml
        description
        isLook: metafield(namespace: "custom", key: "islook") {
            value
            type
        }
        handle
        seo {
            description
            title
        }
        image {
            id
            url
            width
            height
            altText
        }
    }
`

export const PRODUCT_CONNECTION_PAGINATED_FIELDS = `
    #graphql
    ${PRODUCT_FIELDS}
    ${PRODUCT_IMAGE_FIELDS}
    ${PRODUCT_VARIANT_FIELDS}
    fragment ProductConnectionPaginatedFields on ProductConnection {
        filters {
            id
            label
            type
            values {
                id
                label
                count
                input
            }
        }
        edges {
            cursor
            node {
                images(first:1) {
                    edges {
                        node {
                            ...ProductImageFields
                        }
                    }
                }
                ...ProductFields
                variants(first: 1) {
                    edges {
                        node {
                            ...ProductVariantFields
                        }
                    }
                }
            }
        }
        pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
        }
    }`;