import Image from 'next/image'
import {useState} from "react";
import {classNames} from "../../lib/functions";
import {currSymbol} from "../../lib/currencies";

/**
 * @param href
 * @param imageHeightStyle
 * @param brand
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProductImageLink({ product, imageHeightStyle, currencyCode = 'EUR', brand, setPrevPath = false, router}) {
  const [isLoaded, setIsLoaded] = useState(false);

  const { images, title, variants } = product;
  const href = `/products/${product.handle}`;
  const variant = variants?.[0];
  const soldout = product?.totalInventory === 0;

  const onClick = (e) => {
    e.preventDefault();

    const { asPath } = router;

    sessionStorage.setItem(asPath + ':scroll', window.scrollY.toString());

    const routeParams = {
      pathname: href,
      query: {}
    };

    router.push(routeParams, undefined, {scroll: true});
  }

  return (
    <a href={href} className="w-full h-full" onClick={onClick}>
      <div className="w-full h-full relative hover:opacity-90" style={imageHeightStyle}>
        <Image
          alt="product_image"
          className={classNames(isLoaded ? 'opacity-1': 'opacity-0', "transition duration-300 ease-in-out block")}
          layout='fill'
          objectPosition="center"
          objectFit="cover"
          loading="lazy"
          lazyBoundary="5px"
          onLoadingComplete={() => setIsLoaded(true)}
          src={images?.[0].transformedSrc ?? "https://dummyimage.com/400x400?text=No+Picture :("}
        />
        <div className="absolute bottom-0 z-1 py-4 px-2">
          { soldout &&
          <span className="badge badge-xs px-3 py-2 badge-content indicator-item">Sold out</span>
          }
        </div>
      </div>
      <div className="mt-2 text-center">
        <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1">{brand}</h3>
        <h2 className="text-gray-900 title-font text-lg font-medium">{title}</h2>
        <span className="text-gray-600 text-sm">{currSymbol(variant.priceV2.currencyCode)}{variant.priceV2.amount}</span>
      </div>
    </a>
  );
}