import CollectionImageLink from "../collections/CollectionImageLink";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

export default function LookElement(props) {
  const { collections } = props;

  const looks = JSON.parse(collections) || [];

  return (
    <div className="w-full h-full">
      <ResponsiveMasonry
        columnsCountBreakPoints={{350: 2, 750: 2, 900: 3}}
      >
        <Masonry>
          {looks.map((look, index) => (
            <div className="px-2 py-1">
              <CollectionImageLink key={index} prefixUrl={'shop-the-look'} collection={look} />
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}