/**
 *
 * @param product
 * @returns {JSX.Element}
 * @constructor
 */
import useAutoHeightImage from "../common/AutoHeightImage";
import {useRouter} from "next/router";

import ProductImageLink from "../frontpage/ProductImageLink";
import {MdArrowForwardIos} from "react-icons/md";
import { first } from "lodash";
import React, {cloneElement, forwardRef, isValidElement, useEffect, useImperativeHandle, useRef, useState} from "react";

const Actions = ({carouselRef}) => {
  const [hasPrev, setHasPrev] = useState(true);
  const [hasNext, setHasNext] = useState(true);

  const next = () => {
    const {scrollWidth, scrollLeft, scrollUnit} = carouselRef.current.next();
    // setHasPrev((scrollLeft - scrollUnit) > 0);
    // setHasNext(scrollWidth - (scrollLeft + 2 * scrollUnit) > 0);
  }

  const prev = () => {
    const {scrollWidth, scrollLeft, scrollUnit} = carouselRef.current.prev();
    // setHasPrev((scrollLeft - scrollUnit) > 0);
    // setHasNext(scrollWidth - (scrollLeft + 2 * scrollUnit) > 0);
  }

  return (
    <div className="absolute pointer-events-none w-full h-full z-10">
      <div className="flex h-full justify-between">
        <div className="relative w-1/8">
          <div className="flex justify-center items-center h-full pl-5">
            {hasPrev &&
            <div onClick={prev}
                 className="invisible lg:visible w-[2rem] h-[2rem] btn-circle pointer-events-initial flex justify-center items-center bg-accent-content text-gray-500 hover:scale-125 duration-200 ease-in-out origin-center transition-all cursor-pointer">
              <div className="flip">
                <MdArrowForwardIos size={12} />
              </div>
            </div>
            }
          </div>
        </div>
        <div className="relative w-1/8">
          <div className="flex justify-center items-center h-full pr-5">
            {hasNext &&
            <div onClick={next}
                 className="invisible lg:visible pointer-events-initial btn-circle w-[2rem] h-[2rem] flex justify-center items-center bg-accent-content text-gray-500 hover:scale-125 duration-200 ease-in-out origin-center transition-all cursor-pointer">
              <MdArrowForwardIos size={12}/>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

const Carousel = forwardRef(({ children, items, preview, showFooter = true }, ref ) => {
  const itemsRef = useRef()

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.scroll({ left: 0 });
    }
  }, [itemsRef.current])

  useImperativeHandle(ref, () => ({
    next() {
      const firstItem = first(itemsRef.current.querySelectorAll(".scroller-item"));

      const { width: itemWidth } = firstItem.getBoundingClientRect();
      const fullItemWith = itemWidth;

      const currentWindowWidth = window.innerWidth;
      const multiplier = (currentWindowWidth > 1024) ? 1: 1;
      const scrollUnit = fullItemWith * multiplier;

      itemsRef.current.scroll({ left: itemsRef.current.scrollLeft + scrollUnit });

      return {
        scrollLeft: itemsRef.current.scrollLeft,
        scrollWidth: itemsRef.current.scrollWidth,
        scrollUnit: scrollUnit,
      };
    },
    prev() {
      const firstItem = first(itemsRef.current.querySelectorAll(".scroller-item"));
      const { width: itemWidth } = firstItem.getBoundingClientRect();
      const fullItemWith = itemWidth;

      const currentWindowWidth = window.innerWidth;
      const multiplier = (currentWindowWidth > 1024) ? 1: 1;
      const scrollUnit = fullItemWith * multiplier;

      itemsRef.current.scroll({ left: itemsRef.current.scrollLeft - scrollUnit});

      return {
        scrollLeft: itemsRef.current.scrollLeft,
        scrollWidth: itemsRef.current.scrollWidth,
        scrollUnit: scrollUnit,
      };
    }
  }));

  return (
    <div ref={itemsRef} className="scroller sm:no-scrollbar relative overflow-x-auto overflow-y-visible flex flex-nowrap">

      {items.map((item, index) => (
        <div key={index} className="scroller-item cursor-pointer px-3">
          { children(item, index) }
        </div>
      ))}
    </div>
  );
});

/**
 * @param product
 * @returns {JSX.Element}
 * @constructor
 */
export default function GenericCarousel({ items, children, carouselOptions = null }) {
  const carouselRef = useRef();

  return (
    <div className="w-full mr-[10rem]">
      <div className="w-full">
        <div className="relative">
          <Actions carouselRef={carouselRef} imageCount={items.length} />

          <Carousel
            ref={carouselRef}
            items={items}
          >
            { (item, index) => (
              <>
                { children(item, index) }
              </>
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
}