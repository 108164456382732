import '@builder.io/widgets';

import {Builder, builder, BuilderComponent} from '@builder.io/react'
import {CarouselComponent} from "@builder.io/widgets/dist/lib/components/Carousel";
import {carouselConfig} from "@builder.io/widgets/dist/lib/components/Carousel.config";
import NewArrivals from "../components/builder/NewArrivals";
import NewsLetterFooter from "../components/builder/NewsLetterFooter";
import Footer from "../components/footer";
import ShopTheLookOutfitGrid from "../components/builder/ShopTheLookOutfitGrid";
import LookElement from "../components/builder/LookElement";
import LooksCarousel from "../components/builder/LooksCarousel";

builder.init('38a3bbda032f4d4ab2e3e179e81b1634')

carouselConfig.name = 'Carousel';

Builder.registerComponent(CarouselComponent, carouselConfig);
Builder.registerComponent(NewArrivals, {
  name: 'NewArrivals',
  inputs: [{ name: 'title', type: 'text' }]
});

Builder.registerComponent(NewsLetterFooter, {
  name: 'NewsLetterFooter',
  inputs: [
    { name: 'title', type: 'text' },
    { name: 'subtitle', type: 'text' },
    { name: 'backgroundColor', type: 'color' },
  ]
});

Builder.registerComponent(Footer, {
  name: 'Footer',
  inputs: [
    { name: 'backgroundColor', type: 'color' },
    { name: 'fontColor', type: 'color' },
  ]
});

Builder.registerComponent(ShopTheLookOutfitGrid, {
  name: 'ShopTheLookOutfitGrid',
  inputs: [
    { name: 'backgroundColor', type: 'color' },
    { name: 'fontColor', type: 'color' },
    {
      name: 'looks',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'text',
          defaultValue: 'Title',
        },
        {
          name: 'handle',
          type: 'text',
          defaultValue: 'Collection Handle',
        },
        {
          name: 'content',
          type: 'uiBlocks',
          defaultValue: [],
        },
      ],
      defaultValue: [
        {
          label: 'Tab 1',
          content: [],
        },
      ],
    }
  ],
});

Builder.registerComponent(LooksCarousel, {
  name: 'ShopTheLookCarousel',
  inputs: [
    {
      name: 'collections',
      type: 'text',
    },
  ],
});

Builder.registerComponent(LookElement, {
  name: 'ShopTheLookElement',
  inputs: [
    {
      name: 'collections',
      type: 'text',
    },
  ],
});

export { Builder, builder, BuilderComponent}
