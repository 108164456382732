import GenericCarousel from "../product-details/GenericCarousel";
import CollectionResponsiveImageLink from "../collections/CollectionResponsiveImageLink";
import useAutoHeightImage from "../common/AutoHeightImage";

export default function LooksCarousel(props) {
  const { collections } = props;

  let looks = [];

  if (collections && typeof collections === 'string') {
    looks = JSON.parse(collections);
  } else if (collections) {
    looks = collections;
  }

  const carouselOptions = {
    xs: 1.4,
    sm: 2.5,
    md: 3,
    lg: 3,
    xl: 4.5,
    xxl: 8.5,
    min: 300,
  };

  const autoHeightImage = useAutoHeightImage(carouselOptions);

  return (
    <>
      {looks.length > 0 &&
        <GenericCarousel
          items={looks}
        >
          { (item, index) => (
            <CollectionResponsiveImageLink
              key={index}
              prefixUrl={'shop-the-look'}
              imageHeightStyle={autoHeightImage}
              collection={item}
            />
          )}
        </GenericCarousel>
      }
    </>
  );
}