import transformCollection, {transformCollections} from "../graphql-transformers/transformers";
import {COLLECTION_FIELDS, PRODUCT_CONNECTION_PAGINATED_FIELDS} from "./fragments/CollectionFields";

/***
 * @param axiosInstance
 * @param handle
 * @param cursor
 * @param countryCode
 * @returns {Promise<T>}
 */
export const getCollectionByHandleLastAndBefore = async (axiosInstance, {handle, cursor = "", filters = [], last = 20}) => {
  const QUERY = `
      #graphql
      ${COLLECTION_FIELDS}
      ${PRODUCT_CONNECTION_PAGINATED_FIELDS}
      query getCollectionByHandle(
          $handle: String!
          $filters: [ProductFilter!]
          $languageCode: LanguageCode!
          $countryCode: CountryCode!
          $cursor: String
          $last: Int!
      ) @inContext(country: $countryCode, language: $languageCode) {
          collection(handle: $handle) {
              ...CollectionFields
              products(last: $last, filters: $filters, before: $cursor) {
                  ...ProductConnectionPaginatedFields
              }
          }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      handle,
      last: last,
      filters: filters,
      cursor: cursor,
      countryCode: "NL",
      languageCode: "EN",
    },
  }).catch(e => console.log(e));

  if (response.data?.data?.collection) {
    return transformCollection(response.data.data.collection);
  } else {
    return response.data;
  }
}

/***
 * @param axiosInstance
 * @param handle
 * @param cursor
 * @param filters
 * @param countryCode
 * @param languageCode
 * @param first
 * @returns {Promise<T>}
 */
export const getCollectionByHandleFirstAndAfter = async (axiosInstance, {handle, cursor, filters = [], countryCode = 'NL', languageCode = 'EN',  first = 20, }) => {
  const QUERY = `
      #graphql
      ${COLLECTION_FIELDS}
      ${PRODUCT_CONNECTION_PAGINATED_FIELDS}
      query getCollectionByHandle(
          $handle: String!
          $filters: [ProductFilter!]
          $languageCode: LanguageCode!
          $countryCode: CountryCode!
          $cursor: String
          $first: Int!
      ) @inContext(country: $countryCode, language: $languageCode) {
          collection(handle: $handle) {
              ...CollectionFields
              products(first: $first, filters: $filters, after: $cursor) {
                  ...ProductConnectionPaginatedFields
              }
          }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      handle,
      first: first,
      filters: filters,
      cursor: cursor,
      countryCode: "NL",
      languageCode: "EN",
    },
  }).catch(e => {console.log(e); return null});

  if (response?.data?.data?.collection) {
    return transformCollection(response.data.data.collection);
  } else {
    return response;
  }
}

/***
 * @returns {Promise<T>}
 */
export const getCollections = async (axiosInstance, { first = 100} ) => {
  // const QUERY = `{
  //     collections(first: 20) {
  //       edges {
  //         cursor
  //         node {
  //             title
  //             handle
  //             descriptionHtml
  //             description
  //             seo {
  //                 description
  //                 title
  //             }
  //             image {
  //                 id
  //                 url
  //                 width
  //                 height
  //                 altText
  //             }
  //         }
  //     }
  //     pageInfo {
  //         endCursor
  //         hasNextPage
  //     }
  //   }
  // }`;
  const QUERY = `
      #graphql
      ${COLLECTION_FIELDS}
      query getCollections(
          $languageCode: LanguageCode!
          $countryCode: CountryCode!
          $first: Int!
      ) @inContext(country: $countryCode, language: $languageCode) {
          collections(first: $first) {
              edges {
                  node {
                      ...CollectionFields
                  }
              }
          }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      first: first,
      countryCode: "NL",
      languageCode: "EN",
    },
  }).catch(e => console.log(e));

  console.log('===', response.data)

  if (response?.data?.data?.collections) {
    return response.data.data.collections.edges.map((c) => transformCollection(c.node));
  } else {
    return [];
  }
}

/**
 * @param axiosInstance
 * @param handle
 * @param cursor
 * @param filters
 * @param countryCode
 * @param languageCode
 * @param first
 * @returns {Promise<(*)[]>}
 */
export const searchCollectionFirstAndAfter = async (axiosInstance, {query, cursor, filters = [], countryCode = 'NL', languageCode = 'EN',  first = 20, }) => {
  const QUERY = `
      #graphql
      ${COLLECTION_FIELDS}
      ${PRODUCT_CONNECTION_PAGINATED_FIELDS}
      query collections(
          $query: String
          $filters: [ProductFilter!]
          $languageCode: LanguageCode!
          $countryCode: CountryCode!
          $cursor: String
          $first: Int!
      ) @inContext(country: $countryCode, language: $languageCode) {
          collections(query: $query, first: $first) {
              edges {
                  node {
                      ...CollectionFields
                      products(first: $first, filters: $filters, after: $cursor) {
                          ...ProductConnectionPaginatedFields
                      }
                  }
              }
          }
      }
  `;

  const response = await axiosInstance.post('/api/2022-07/graphql.json', {
    query: QUERY,
    variables: {
      query,
      first: first,
      filters: filters,
      cursor: cursor,
      countryCode: "NL",
      languageCode: "EN",
    },
  }).catch(e => {console.log(e); return null});

  if (response?.data?.data?.collections) {
    return response.data.data.collections.edges.map((c) => transformCollection(c.node));
  } else {
    return [];
  }
}
