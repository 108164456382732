import {useEffect, useState} from "react";
import {searchCollectionFirstAndAfter} from "../../lib/resources/collections";
import axiosStorefrontInstance from "../../lib/axios-instances/axios-storefront-instance";
import useAutoHeightImage from "../common/AutoHeightImage";
import CollectionImageLink from "../collections/CollectionImageLink";
import {RiLoader4Fill} from "react-icons/ri";
import {isEmpty} from "lodash";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

export default function ShopTheLookOutfitGrid(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [collections, setCollections] = useState([]);

  const autoHeightImage = useAutoHeightImage({
    xs: 1.1,
    sm: 2,
    md: 2.1,
    lg: 3.2,
    xl: 3.2,
    xxl: 3.6,
    min: 200,
    equalDims: true
  });

  useEffect(() => {
    if (isEmpty(collections) && isLoading === true) {
      return searchCollectionFirstAndAfter(axiosStorefrontInstance, {
        first: 20,
      }).then((collections ) => {
        const collectionsFiltered = collections.filter((c) => {
          return c.image && c?.isLook?.value === 'true';
        });

        setCollections(collectionsFiltered);
      }).finally(() => setIsLoading(false))
    }
  }, [])

  return (
    <>
      <div className="flex flex-wrap gap-3 px-2">
        {!isLoading &&
          <ResponsiveMasonry
            columnsCountBreakPoints={{350: 2, 750: 2, 900: 3}}
          >
            <Masonry>
              {collections.map((collection, index) => (
                <div className="px-2 py-1">
                  <CollectionImageLink key={index} collection={collection}/>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        }
        { isLoading &&
          <div className="w-full bottom-10">
            {
              <div className="flex flex-col items-center min-h-12 justify-center mx-auto">
                <RiLoader4Fill className="icon-spin" size={50} />
                <p className="font-xl font-mazzard">Loading our LENTO looks...</p>
              </div>
            }
          </div>
        }
      </div>
    </>
  );
}