import {useState} from "react";
import Link from "next/link";
import Image from 'next/image'
import {classNames} from "../../lib/functions";

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function CollectionResponsiveImageLink({collection, prefixUrl = 'collections', imageHeightStyle = {}}) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Link href={`/${prefixUrl}/${collection.handle}`}>
      <a>
        <div className="w-full h-full relative hover:opacity-90" style={imageHeightStyle}>
          {/*<Image*/}
          {/*  alt="product_image"*/}
          {/*  layout="intrinsic"*/}
          {/*  className={"cover"}*/}
          {/*  width={collection?.image?.width}*/}
          {/*  height={collection?.image?.height}*/}
          {/*  objectPosition="top"*/}
          {/*  objectFit="cover"*/}
          {/*  loading="lazy"*/}
          {/*  lazyBoundary="5px"*/}
          {/*  onLoadingComplete={() => setIsLoaded(true)}*/}
          {/*  src={collection?.image?.url ?? "https://dummyimage.com/400x400?text=No+Picture :("}*/}
          {/*/>*/}
          <Image
            alt="product_image"
            className={classNames(isLoaded ? 'opacity-1': 'opacity-0', "transition duration-300 ease-in-out block")}
            layout='fill'
            width={collection?.image?.width}
            height={collection?.image?.height}
            objectPosition="center"
            objectFit="cover"
            loading="lazy"
            lazyBoundary="5px"
            onLoadingComplete={() => setIsLoaded(true)}
            src={collection?.image?.url ?? "https://dummyimage.com/400x400?text=No+Picture :("}
          />

          <div className="image-overlay z-2 w-full bottom-0 h-full absolute">
            <div className="flex justify-center w-full h-full items-end bottom-0 p-2">
              <h2 className="font-mazzard font-bold uppercase lg:text-xl shadow-xl text-shadow-heavy font-medium text-center text-white pb-6 max-w-[20rem]">
                { collection.title
                }</h2>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
