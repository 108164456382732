import {useEffect, useState} from "react";
import {getCollectionByHandleFirstAndAfter} from "../../lib/resources/collections";
import GenericCarousel from "../product-details/GenericCarousel";
import axiosStorefrontInstance from "../../lib/axios-instances/axios-storefront-instance";
import ProductImageLink from "../frontpage/ProductImageLink";
import useAutoHeightImage from "../common/AutoHeightImage";
import {useRouter} from "next/router";

export default function NewArrivals() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    return getCollectionByHandleFirstAndAfter(axiosStorefrontInstance, {
      handle: 'new-arrivals',
      first: 20,
    }).then((data) => {
      setProducts(data.products);
    })

  }, [])

  const carouselOptions = {
    xs: 1.4,
    sm: 1.5,
    md: 2,
    lg: 2,
    xl: 3.5,
    xxl: 3.5,
    min: 200,
  };

  const router = useRouter();
  const autoHeightImage = useAutoHeightImage(carouselOptions);

  return (
    <>
      {products.length > 0 &&
        <GenericCarousel
          items={products}
          carouselOptions={carouselOptions}
        >
          { (item, index) => (
            <ProductImageLink
              imageHeightStyle={autoHeightImage}
              setPrevPath={true}
              router={router}
              index={index}
              product={item}
            />
          )}
        </GenericCarousel>
      }
    </>
  );
}