/**
 * @constructor
 */
import {BiArrowBack} from "react-icons/bi";
import {useRouter} from "next/router";
import {classNames} from "../../lib/functions";

const styleTypes = {
  white: 'btn bg-white text-gray-800 hover:bg-white hover:text-gray-800 border-none',
  black: 'btn btn-outline',
}

const iconStyleType = {
  white: 'white',
  black: 'black',
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function BackButton({ styleType = 'black'}) {
  const router = useRouter();

  const { query } = router;

  const onClick = () => {
    if (query.prevPath) {
      router.push(`${query.prevPath}`, undefined, {scroll: false}).then(() => {
        const y = sessionStorage.getItem(query.prevPath + ':scrollPos') || 0;

        window.scrollTo(0, parseInt(y));
      });
    } else {
      router.back();
    }
  }

  const classes = classNames(styleTypes[styleType]);

  return (
    <button onClick={onClick} className={classes}>
      <BiArrowBack size={40} />
    </button>
  )
}